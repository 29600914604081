.LinkSection {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 40px;
}

.SocialIconWrapper {
  height: 36px;
  width: 36px;
}

.SocialIconWrapper + .SocialIconWrapper {
  margin-left: 40px;
}

.SocialIcon {
  height: 36px;
  width: 36px;
  color: black;
}

.EnvelopeIcon {
  margin-top: 4px;
}