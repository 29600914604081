.Header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  text-decoration: none;
}

.Header-left {
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: #000000;
  flex-grow: 0;
  flex-shrink: 1;
}

.Header-right {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #000000;
  color: rgb(29, 162, 184);
  flex-grow: 0;
  flex-shrink: 1;
}

.Header-right-item {
  font-size: 16px;
  font-weight: 400;
  margin-left: 35px;
  text-decoration: none;
  color: #000000;
}

.Header-right-item:hover {
  color: #000000;
 }

.Header-right-item.active {
  color: rgb(29, 162, 184);
 }
