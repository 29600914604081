@font-face {
  font-family: 'Lora';
  src: local('Lora'), url(./fonts/Lora/Lora-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'), url(./fonts/OpenSans/OpenSans-VariableFont_wdth,wght.ttf) format('truetype');
}

.AppContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
  font-size: calc(10px + 2vmin);
  border-color: black;
  font-family: 'Open Sans';
}

.Primary-link {
  color: rgb(29, 162, 184);
  font-weight: 600;
  font-size: 18px;
  transition-property: opacity;
  transition-duration: 200ms;
  transition-timing-function: ease;
  text-decoration: none;
}

.Primary-link:hover {
  color: rgb(26, 27, 31);
}
