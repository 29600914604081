.PageContainer {
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
  flex-direction: column;
  max-width: 1200px;
  padding: 0px 128px 128px 128px;

  @media (max-width: 1000px) {
    padding: 0px 64px 64px 64px;
  }

  @media (max-width: 400px) {
    padding: 0px 32px 32px 32px;
  }
}

.PageBody {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: 100px;
  margin-bottom: 0px;

  @media (max-width: 1000px) {
    margin-top: 16px;
  }
}

.PageHeader {
  font-family: Lora;
  font-size: 50px;
  line-height: 60px;
  font-weight: 700;
  white-space: normal;
  text-align: left;
}

.PageSubHeader {
  font-size: 24px;
  margin-top: 16px;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: left;
}

.PageText {
  margin-left: 0px;
  font-size: 18px;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
  text-align: start;
  line-height: 28px;
}