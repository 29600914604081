.HomeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;

  @media (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }
}

.BodyLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 64px;
  min-width: 0;
  max-width: 600px;

  @media (max-width: 1000px) {
    margin-right: 0px;
    max-width: 1000px;
  }
}

.BodyRight {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  /* background-color: pink; */
}

.GreetingImage {
  width:100%;
  height:100%;
  object-fit:contain;

  @media (max-width: 1000px) {
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

