
.ResumeButton {
  padding: 5px 20px;
  margin-top: 32px;
  margin-bottom: 32px;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(29, 162, 184);
  text-decoration: none;
}

.Label {
  margin: 0px;
  color: rgb(29, 162, 184);
  font-size: 18px;
  font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}